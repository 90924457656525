

































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';
import { useSearchInput } from '~/composables';
import { APP_ROUTER_PATHS } from '~/constants';

export default defineComponent({
  components: {
    SvgImage,
  },
  setup() {
    const { searchInput, doSearch } = useSearchInput();

    const inputRef = ref('');
    const searchTerm = computed(() => `${searchInput.value}`);

    const handleSearch = () => doSearch(inputRef.value);

    return {
      searchTerm,
      inputRef,
      contactPath: APP_ROUTER_PATHS.contact,
      list: [
        'Check your spelling', 'Use spaces between words', 'Try using fewer words',
        'Remember: you can search for your printer model name, or the product code',
      ],
      handleSearch,
    };
  },
});
